import React, { useEffect, useState } from "react";
import "./App.css";

type GameStatus = "CREATED" | "SETTINGS" | "JOINED" | "INPROGRESS" | "FINISHED";

type Size = "large" | "medium" | "small";
const sizes: Size[] = ["large", "medium", "small"];
type Color = "red" | "green" | "blue" | "purple";
const colors: Color[] = ["red", "green", "blue", "purple"];
type Circle = { color: Color | undefined; winner: boolean };
type SquareCircles = {
  large: Circle;
  medium: Circle;
  small: Circle;
};
type Player = {
  player: "YOU" | "BOT";
  color: Color | undefined;
  large: number;
  medium: number;
  small: number;
  winns: number;
};

function Square({
  value,
  onSquareClick,
}: {
  value: SquareCircles;
  onSquareClick: Function;
}) {
  function handleOnSquareClick() {
    onSquareClick();
  }
  return (
    <button className="square" onClick={handleOnSquareClick}>
      <div
        className={
          value.large.color &&
          `circle large ${value.large.color} ${
            value.large.winner ? `winner` : `loser`
          }`
        }
      ></div>
      <div
        className={
          value.medium.color &&
          `circle medium ${value.medium.color} ${
            value.medium.winner ? `winner` : `loser`
          }`
        }
      ></div>
      <div
        className={
          value.small.color &&
          `circle small ${value.small.color} ${
            value.small.winner ? `winner` : `loser`
          }`
        }
      ></div>
    </button>
  );
}

function Board({
  squares,
  handleClick,
  finished,
}: {
  squares: SquareCircles[][];
  handleClick: Function;
  finished: Boolean;
}) {
  return (
    <>
      <div className={finished && `finished`}>
        {squares.map((row, i) => (
          <div className="board-row" key={`row-${i}`}>
            {row.map((box, j) => (
              <Square
                value={box}
                onSquareClick={() => handleClick(i, j)}
                key={`box-${i}-${j}`}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

function Players({
  players,
  setGameStatus,
}: {
  players: Array<Player>;
  setGameStatus: Function;
}) {
  return (
    <>
      <div className="info board-row">
        <div className="players-grid">
          {players.map((player) => (
            <div className="flex" key={player.color}>
              <button className="small-square">
                <div className={`circle small ${player.color}`}></div>
              </button>
              <div>: {player.winns}</div>
            </div>
          ))}
        </div>
        <button className="square" onClick={() => setGameStatus("SETTINGS")}>
          <span className="number">{players.length}</span>
          players
        </button>
      </div>
    </>
  );
}

function PlayerInfo({
  currentPlayer,
  player,
  setCircleSize,
  circleSize,
}: {
  currentPlayer: number;
  player: Array<Player>;
  setCircleSize: Function;
  circleSize: Size | null;
}) {
  return (
    <div className="info">
      <div className="board-row finished">
        <button
          className={`square number ${
            !player[currentPlayer].large && `loser`
          } ${circleSize !== "large" && circleSize && `loser`}`}
          onClick={() => setCircleSize("large")}
        >
          <div className={`circle large ${player[currentPlayer].color}`}></div>
          {player[currentPlayer].large}
        </button>
        <button
          className={`square number ${
            !player[currentPlayer].medium && `loser`
          } ${circleSize !== "medium" && circleSize && `loser`}`}
          onClick={() => setCircleSize("medium")}
        >
          <div className={`circle medium ${player[currentPlayer].color}`}></div>
          {player[currentPlayer].medium}
        </button>
        <button
          className={`square number ${
            !player[currentPlayer].small && `loser`
          } ${circleSize !== "small" && circleSize && `loser`}`}
          onClick={() => setCircleSize("small")}
        >
          <div className={`circle small ${player[currentPlayer].color}`}></div>
          {player[currentPlayer].small}
        </button>
      </div>
    </div>
  );
}

export default function App() {
  const [gameStatus, setGameStatus] = useState<GameStatus>("CREATED");
  const [squares, setSquares] = useState<Array<Array<SquareCircles>>>(
    Array(3).fill(
      Array(3).fill({
        large: {
          color: null,
          winner: false,
        },
        medium: {
          color: null,
          winner: false,
        },
        small: {
          color: null,
          winner: false,
        },
      })
    )
  );
  const [playersNumber, setPlayersNumber] = useState(0);
  const [players, setPlayers] = useState<Array<Player>>([]);
  const [currentPlayer, setCurrentPlayer] = useState<number>(0);
  const [winner, setWinner] = useState<number | null>(null);
  const [circleSize, setCircleSize] = useState<Size | null>(null);
  const [gameOver, setGameOver] = useState<boolean>(true);

  function handleClick(i: number, j: number) {
    if (winner !== null || players[currentPlayer].player === "BOT") return;

    if (circleSize && !squares[i][j][circleSize].color) {
      const nextSquares = [...squares];
      nextSquares[i] = [...squares[i]];
      nextSquares[i][j] = { ...squares[i][j] };
      nextSquares[i][j][circleSize] = { ...squares[i][j][circleSize] };
      nextSquares[i][j][circleSize].color = players[currentPlayer].color;
      let win = calculateWinner(
        nextSquares,
        circleSize,
        players[currentPlayer].color,
        i,
        j,
        false
      );
      setSquares(nextSquares);
      handlePlay(circleSize);
      if (win !== null) {
        let p = [...players];
        ++p[win].winns;
        setPlayers(p);
        setWinner(win);
        setGameOver(true);
        console.log(winner, gameOver);
      }
    }
  }

  function playBot() {
    let win = null;
    const nextSquares = [...squares];
    let i = Math.floor(Math.random() * 3);
    let j = Math.floor(Math.random() * 3);
    for (let x = 0; x < 3; x++) {
      for (let y = 0; y < 3; y++) {
        for (let s = 0; s < 3; s++) {
          if (
            win === null &&
            players[currentPlayer][sizes[s]] &&
            !squares[x][y][sizes[s]].color
          ) {
            win = calculateWinner(
              nextSquares,
              sizes[s],
              players[currentPlayer].color,
              x,
              y,
              false
            );
            if (win !== null) {
              i = x;
              j = y;
              setCircleSize(sizes[s]);
            }
          }
        }
      }
    }
    if (win === null) {
      for (let p = currentPlayer + 1; p < players.length; p++) {
        for (let x = 0; x < 3; x++) {
          for (let y = 0; y < 3; y++) {
            for (let s = 0; s < 3; s++) {
              if (
                win === null &&
                players[currentPlayer][sizes[s]] &&
                players[p][sizes[s]] &&
                !squares[x][y][sizes[s]].color
              ) {
                let pWin = calculateWinner(
                  nextSquares,
                  sizes[s],
                  players[p].color,
                  x,
                  y,
                  true
                );
                if (pWin !== null) {
                  win = p;
                  i = x;
                  j = y;
                  setCircleSize(sizes[s]);
                }
              }
            }
          }
        }
      }
      for (let p = 0; p < currentPlayer; p++) {
        for (let x = 0; x < 3; x++) {
          for (let y = 0; y < 3; y++) {
            for (let s = 0; s < 3; s++) {
              if (
                win === null &&
                players[currentPlayer][sizes[s]] &&
                players[p][sizes[s]] &&
                !squares[x][y][sizes[s]].color
              ) {
                let pWin = calculateWinner(
                  nextSquares,
                  sizes[s],
                  players[p].color,
                  x,
                  y,
                  true
                );
                if (pWin !== null) {
                  win = p;
                  i = x;
                  j = y;
                  setCircleSize(sizes[s]);
                }
              }
            }
          }
        }
      }
    }
    if (win === null) {
      let n = sizes[Math.floor(Math.random() * 3)];
      while (players[currentPlayer][n] === 0) {
        n = sizes[Math.floor(Math.random() * 3)];
      }
      setCircleSize(n);
      while (circleSize && squares[i][j][circleSize].color) {
        i = Math.floor(Math.random() * 3);
        j = Math.floor(Math.random() * 3);
      }
    }

    if (circleSize && !squares[i][j][circleSize].color) {
      nextSquares[i] = [...squares[i]];
      nextSquares[i][j] = { ...squares[i][j] };
      nextSquares[i][j][circleSize] = { ...squares[i][j][circleSize] };
      nextSquares[i][j][circleSize].color = players[currentPlayer].color;
      setSquares(nextSquares);
      if (win === currentPlayer) {
        let p = [...players];
        ++p[win].winns;
        setPlayers(p);
        setWinner(win);
        setGameOver(true);
        console.log(winner, gameOver);
      }
      handlePlay(circleSize);
    }
  }

  useEffect(() => {
    if (
      winner === null &&
      players.length &&
      players[currentPlayer].player === "BOT"
    ) {
      const timeoutId = setTimeout(playBot, 100);
      return () => clearTimeout(timeoutId);
    }
  });

  function calculateWinner(
    nextSquares: Array<Array<SquareCircles>>,
    size: Size,
    color: Color | undefined,
    i: number,
    j: number,
    botscheck: boolean
  ) {
    let win = currentPlayer;
    let sizes: Array<Size> = ["large", "medium", "small"];
    for (let k = 0; k < 3; k++) {
      if (sizes[k] !== size && squares[i][j][sizes[k]].color !== color) {
        win = -1;
      }
    }
    // function check(x1,y1,x2,y2,x3,y3, color) {

    // }
    // check(0,0,0,0,0,0)
    if (win === currentPlayer && !botscheck) {
      // const nextSquares = [...squares];
      // nextSquares[i] = [...squares[i]];
      // nextSquares[i][j] = { ...squares[i][j] };
      for (let k = 0; k < 3; k++) {
        // nextSquares[i][j][sizes[k]] = { ...squares[i][j][sizes[k]] };
        nextSquares[i][j][sizes[k]].winner = true;
      }
      setSquares(nextSquares);
    }
    function calculateBetween(x1: number, y1: number, x2: number, y2: number) {
      for (let k = 0; k < 3; k++) {
        if (squares[x1][y1][sizes[k]].color === color) {
          for (let l = 0; l < 3; l++) {
            if (squares[x2][y2][sizes[l]].color === color) {
              if (
                sizes[k] !== size &&
                sizes[l] !== size &&
                sizes[k] !== sizes[l]
              ) {
                win = currentPlayer;
                if (!botscheck) {
                  // setWinner(x1,y1,size1,x2,y2,size2)
                  // const nextSquares = [...squares];
                  // nextSquares[i] = [...squares[i]];
                  // nextSquares[x1] = [...squares[x1]];
                  // nextSquares[x2] = [...squares[x2]];
                  // nextSquares[i][j] = { ...squares[i][j] };
                  // nextSquares[x1][y1] = { ...squares[x1][y1] };
                  // nextSquares[x2][y2] = { ...squares[x2][y2] };
                  // nextSquares[i][j][size] = { ...squares[i][j][size] };
                  nextSquares[i][j][size].winner = true;
                  // nextSquares[x1][y1][sizes[k]] = {
                  //   ...squares[x1][y1][sizes[k]],
                  // };
                  nextSquares[x1][y1][sizes[k]].winner = true;
                  // nextSquares[x2][y2][sizes[l]] = {
                  //   ...squares[x2][y2][sizes[l]],
                  // };
                  nextSquares[x2][y2][sizes[l]].winner = true;

                  setSquares(nextSquares);
                }
              }
              if (sizes[k] === size && sizes[k] === sizes[l]) {
                win = currentPlayer;
                if (!botscheck) {
                  // const nextSquares = [...squares];
                  // nextSquares[i] = [...squares[i]];
                  // nextSquares[x1] = [...squares[x1]];
                  // nextSquares[x2] = [...squares[x2]];
                  // nextSquares[i][j] = { ...squares[i][j] };
                  // nextSquares[x1][y1] = { ...squares[x1][y1] };
                  // nextSquares[x2][y2] = { ...squares[x2][y2] };
                  // nextSquares[i][j][size] = { ...squares[i][j][size] };
                  nextSquares[i][j][size].winner = true;
                  // nextSquares[x1][y1][sizes[k]] = {
                  //   ...squares[x1][y1][sizes[k]],
                  // };
                  nextSquares[x1][y1][sizes[k]].winner = true;
                  // nextSquares[x2][y2][sizes[l]] = {
                  //   ...squares[x2][y2][sizes[l]],
                  // };
                  nextSquares[x2][y2][sizes[l]].winner = true;

                  setSquares(nextSquares);
                }
              }
            }
          }
        }
      }
    }
    if (i === 0 && j === 0) {
      calculateBetween(0, 1, 0, 2);
      calculateBetween(1, 0, 2, 0);
      calculateBetween(1, 1, 2, 2);
    }
    if (i === 1 && j === 0) {
      calculateBetween(1, 1, 1, 2);
      calculateBetween(0, 0, 2, 0);
    }
    if (i === 2 && j === 0) {
      calculateBetween(2, 1, 2, 2);
      calculateBetween(0, 0, 1, 0);
      calculateBetween(1, 1, 0, 2);
    }
    if (i === 0 && j === 1) {
      calculateBetween(0, 0, 0, 2);
      calculateBetween(1, 1, 2, 1);
    }
    if (i === 1 && j === 1) {
      calculateBetween(1, 0, 1, 2);
      calculateBetween(0, 1, 2, 1);
      calculateBetween(0, 0, 2, 2);
      calculateBetween(2, 0, 0, 2);
    }
    if (i === 2 && j === 1) {
      calculateBetween(2, 0, 2, 2);
      calculateBetween(0, 1, 1, 1);
    }
    if (i === 0 && j === 2) {
      calculateBetween(0, 0, 0, 1);
      calculateBetween(1, 2, 2, 2);
      calculateBetween(1, 1, 2, 0);
    }
    if (i === 1 && j === 2) {
      calculateBetween(1, 0, 1, 1);
      calculateBetween(0, 2, 2, 2);
    }
    if (i === 2 && j === 2) {
      calculateBetween(2, 0, 2, 1);
      calculateBetween(0, 2, 1, 2);
      calculateBetween(0, 0, 1, 1);
    }
    if (win > -1) {
      return win;
    }
    return null;
  }

  function handleJoinGame() {
    setGameStatus("SETTINGS");
  }

  function playAgain() {
    setSquares(
      Array(3).fill(
        Array(3).fill({
          large: {
            color: null,
            winner: false,
          },
          medium: {
            color: null,
            winner: false,
          },
          small: {
            color: null,
            winner: false,
          },
        })
      )
    );
    if (winner !== null) setCurrentPlayer(winner);
    setWinner(null);
    setGameOver(false);
    console.log(winner, gameOver);
    setCircleSize(null);
    resetPlayerList(playersNumber);
  }

  function resetPlayerList(n: number) {
    const p = [...players];
    for (let i = 0; i < n; i++) {
      p[i] = { ...players[i], large: 3, medium: 3, small: 3 };
    }
    setPlayers([...p]);
  }

  function definePlayerList(yours: number, bots: number) {
    const p: [Player] = [
      {
        player: "YOU",
        color: undefined,
        large: 0,
        medium: 0,
        small: 0,
        winns: 0,
      },
    ];
    for (let i = 0; i < yours; i++) {
      p[i] = {
        player: "YOU",
        color: colors[i],
        large: 3,
        medium: 3,
        small: 3,
        winns: 0,
      };
    }
    for (let i = yours; i < yours + bots; i++) {
      p[i] = {
        player: "BOT",
        color: colors[i],
        large: 3,
        medium: 3,
        small: 3,
        winns: 0,
      };
    }
    setPlayers([...p]);
  }

  function handleSellectingPlayersNumber(yours: number, bots: number) {
    if (playersNumber !== yours + bots) {
      playAgain();
      setPlayersNumber(yours + bots);
      definePlayerList(yours, bots);
      setCurrentPlayer(0);
      setGameStatus("JOINED");
    } else {
      setGameStatus("JOINED");
    }
  }

  function handlePlay(circleSize: Size | null) {
    if (circleSize) {
      const p = [...players];
      p[currentPlayer] = { ...players[currentPlayer] };
      --p[currentPlayer][circleSize];
      setPlayers(p);
      setGameOver(
        checkGameOver(
          players[currentPlayer < playersNumber - 1 ? currentPlayer + 1 : 0]
        )
      );
      console.log(winner, gameOver);
      setCurrentPlayer(
        currentPlayer < playersNumber - 1 ? currentPlayer + 1 : 0
      );
      setCircleSize(null);
    }
  }

  function handleSetCircleSize(size: Size) {
    if (
      players[currentPlayer].player === "YOU" &&
      players[currentPlayer][size] > 0
    ) {
      setCircleSize(size);
    }
  }

  function checkAvailableBoardSize(size: Size) {
    console.log(squares);
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (!squares[i][j][size].color) return true;
      }
    }
    return false;
  }

  function checkGameOver(player: Player) {
    if (winner) return true;
    if (!player.large && !player.medium && !player.small) {
      return true;
    }
    if (
      !checkAvailableBoardSize("large") &&
      !checkAvailableBoardSize("medium") &&
      !checkAvailableBoardSize("small")
    ) {
      console.log(
        checkAvailableBoardSize("large"),
        checkAvailableBoardSize("medium"),
        checkAvailableBoardSize("small")
      );
      return true;
    }
    return false;
  }

  return (
    <div className="App">
      <header className="App-header">
        {/* <Game /> */}
        {gameStatus === "CREATED" ? (
          <>
            <h1>OTRIO</h1>
            <button className="start" onClick={handleJoinGame}>
              Play offline
            </button>
          </>
        ) : (
          ""
        )}
        {gameStatus === "SETTINGS" ? (
          <>
            <h1>Select the number of players</h1>
            <div className="board-row">
              <button
                className="square"
                onClick={() => handleSellectingPlayersNumber(2, 0)}
              >
                <span className="number">2</span>players
              </button>
              <button
                className="square"
                onClick={() => handleSellectingPlayersNumber(3, 0)}
              >
                <span className="number">3</span>players
              </button>
              <button
                className="square"
                onClick={() => handleSellectingPlayersNumber(4, 0)}
              >
                <span className="number">4</span>players
              </button>
            </div>
            <p>or</p>
            <h1>Play with bots</h1>
            <div className="board-row">
              <button
                className="square"
                onClick={() => handleSellectingPlayersNumber(1, 1)}
              >
                <span className="number">1</span>bot
              </button>
              <button
                className="square"
                onClick={() => handleSellectingPlayersNumber(1, 2)}
              >
                <span className="number">2</span>bots
              </button>
              <button
                className="square"
                onClick={() => handleSellectingPlayersNumber(1, 3)}
              >
                <span className="number">3</span>bots
              </button>
            </div>
          </>
        ) : (
          ""
        )}
        {gameStatus === "JOINED" ? (
          <>
            <Players players={players} setGameStatus={setGameStatus} />
            <Board
              squares={squares}
              handleClick={handleClick}
              finished={winner !== null}
            />
            {!gameOver ? (
              <PlayerInfo
                currentPlayer={currentPlayer}
                player={players}
                setCircleSize={handleSetCircleSize}
                circleSize={circleSize}
              />
            ) : (
              <div className="info board-row">
                {winner !== null ? (
                  <button className="square">
                    <div
                      className={
                        players[winner] &&
                        `circle large ${players[winner].color}`
                      }
                    ></div>
                    WINNER
                  </button>
                ) : (
                  <button className="square">GAME OVER</button>
                )}

                <button className="square" onClick={playAgain}>
                  Play Again
                </button>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </header>
    </div>
  );
}
